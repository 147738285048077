module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de","hu"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://cvchamp.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false,"keySeparator":"."},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de","hu"]},{"matchPath":"/:lang?/blog/oneletrajz-iras/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/allaskereses/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/oneletrajz-sablonok/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/nemetorszag/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/kulfoldi-oneletrajzok/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/mindennapok/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/hatter/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/blog/background/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","de"]},{"matchPath":"/:lang?/blog/resume-writing/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","de"]},{"matchPath":"/:lang?/blog/resume-templates/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","de"]},{"matchPath":"/:lang?/blog/hintergrund/:uid","getLanguageFromPath":true,"excludeLanguages":["en","hu"]},{"matchPath":"/:lang?/blog/lebenslauf-vorlage/:uid","getLanguageFromPath":true,"excludeLanguages":["en","hu"]},{"matchPath":"/:lang?/blog/lebenslauf-schreiben/:uid","getLanguageFromPath":true,"excludeLanguages":["en","hu"]},{"matchPath":"/:lang?/blog/jobsuche/:uid","getLanguageFromPath":true,"excludeLanguages":["en","hu"]},{"matchPath":"/:lang?/old/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de","hu"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleTagManager":{"trackingId":"GTM-K89BL3T","cookieName":"consentGiven","dataLayerName":"dataLayer","gtmAuth":"Rzo7hhmpEJ3xlxsol7J5xA","gtmPreview":"env-1"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"97dcf0c2fb691f899fc3e77b80963b10"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
