exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-choose-js": () => import("./../../../src/pages/choose.js" /* webpackChunkName: "component---src-pages-choose-js" */),
  "component---src-pages-dataprotection-js": () => import("./../../../src/pages/dataprotection.js" /* webpackChunkName: "component---src-pages-dataprotection-js" */),
  "component---src-pages-ext-about-js": () => import("./../../../src/pages/ext/about.js" /* webpackChunkName: "component---src-pages-ext-about-js" */),
  "component---src-pages-ext-dataprotection-js": () => import("./../../../src/pages/ext/dataprotection.js" /* webpackChunkName: "component---src-pages-ext-dataprotection-js" */),
  "component---src-pages-ext-impressum-js": () => import("./../../../src/pages/ext/impressum.js" /* webpackChunkName: "component---src-pages-ext-impressum-js" */),
  "component---src-pages-ext-index-js": () => import("./../../../src/pages/ext/index.js" /* webpackChunkName: "component---src-pages-ext-index-js" */),
  "component---src-pages-fd-about-js": () => import("./../../../src/pages/fd/about.js" /* webpackChunkName: "component---src-pages-fd-about-js" */),
  "component---src-pages-fd-dataprotection-js": () => import("./../../../src/pages/fd/dataprotection.js" /* webpackChunkName: "component---src-pages-fd-dataprotection-js" */),
  "component---src-pages-fd-impressum-js": () => import("./../../../src/pages/fd/impressum.js" /* webpackChunkName: "component---src-pages-fd-impressum-js" */),
  "component---src-pages-fd-index-js": () => import("./../../../src/pages/fd/index.js" /* webpackChunkName: "component---src-pages-fd-index-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mt-about-js": () => import("./../../../src/pages/mt/about.js" /* webpackChunkName: "component---src-pages-mt-about-js" */),
  "component---src-pages-mt-dataprotection-js": () => import("./../../../src/pages/mt/dataprotection.js" /* webpackChunkName: "component---src-pages-mt-dataprotection-js" */),
  "component---src-pages-mt-impressum-js": () => import("./../../../src/pages/mt/impressum.js" /* webpackChunkName: "component---src-pages-mt-impressum-js" */),
  "component---src-pages-mt-index-js": () => import("./../../../src/pages/mt/index.js" /* webpackChunkName: "component---src-pages-mt-index-js" */),
  "component---src-pages-old-about-js": () => import("./../../../src/pages/old/about.js" /* webpackChunkName: "component---src-pages-old-about-js" */),
  "component---src-pages-old-blog-js": () => import("./../../../src/pages/old/blog.js" /* webpackChunkName: "component---src-pages-old-blog-js" */),
  "component---src-pages-old-choose-js": () => import("./../../../src/pages/old/choose.js" /* webpackChunkName: "component---src-pages-old-choose-js" */),
  "component---src-pages-old-dataprotection-js": () => import("./../../../src/pages/old/dataprotection.js" /* webpackChunkName: "component---src-pages-old-dataprotection-js" */),
  "component---src-pages-old-impressum-js": () => import("./../../../src/pages/old/impressum.js" /* webpackChunkName: "component---src-pages-old-impressum-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old/index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-ausbildung-im-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/ausbildung-im-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-ausbildung-im-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-berufseinsteiger-in-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/berufseinsteiger-in-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-berufseinsteiger-in-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-cv-guide-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/cv-guide.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-cv-guide-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-ehrliche-worte-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/ehrliche-worte.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-ehrliche-worte-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-einfacher-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/einfacher-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-einfacher-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-europass-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/europass-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-europass-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-foto-im-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/foto-im-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-foto-im-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-hobbys-im-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/hobbys-im-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-hobbys-im-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-kompetenzen-im-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/kompetenzen-im-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-kompetenzen-im-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-kurzprofil-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/kurzprofil.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-kurzprofil-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-lebenslauf-typen-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/lebenslauf-typen.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-lebenslauf-typen-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-quo-vadis-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/quo-vadis-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-quo-vadis-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-schueler-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/schueler-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-schueler-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-tabellarischer-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/tabellarischer-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-tabellarischer-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-top-10-fehler-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/top-10-fehler.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-top-10-fehler-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-de-verkaufer-in-lebenslauf-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/de/verkaufer-in-lebenslauf.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-de-verkaufer-in-lebenslauf-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-action-verbs-in-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/action-verbs-in-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-action-verbs-in-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-career-starter-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/career-starter-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-career-starter-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-cv-guide-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/cv-guide.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-cv-guide-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-design-in-resumes-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/design-in-resumes.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-design-in-resumes-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-honest-words-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/honest-words.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-honest-words-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-nursing-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/nursing-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-nursing-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-personal-profile-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/personal-profile.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-personal-profile-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-photo-on-cv-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/photo-on-cv.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-photo-on-cv-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-remote-work-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/remote-work-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-remote-work-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-customization-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-customization.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-customization-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-education-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-education.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-education-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-gaps-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-gaps.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-gaps-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-hobbies-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-hobbies.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-hobbies-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-languages-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-languages.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-languages-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-types-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-types.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-types-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-resume-work-experience-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/resume-work-experience.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-resume-work-experience-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-salesperson-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/salesperson-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-salesperson-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-simple-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/simple-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-simple-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-skills-in-resume-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/skills-in-resume.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-skills-in-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-en-top-10-failure-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/en/top-10-failure.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-en-top-10-failure-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-angol-oneletrajz-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/angol-oneletrajz.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-angol-oneletrajz-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-bolti-elado-oneletrajz-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/bolti-elado-oneletrajz.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-bolti-elado-oneletrajz-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-egyszeru-oneletrajz-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/egyszeru-oneletrajz.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-egyszeru-oneletrajz-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-europass-oneletrajz-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/europass-oneletrajz.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-europass-oneletrajz-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-kivandorlas-nemetorszagba-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/kivandorlas-nemetorszagba.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-kivandorlas-nemetorszagba-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-nemet-oneletrajz-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/nemet-oneletrajz.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-nemet-oneletrajz-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-foto-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oneletrajz-foto.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-foto-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-guide-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oneletrajz-guide.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-guide-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-hobbi-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oneletrajz-hobbi.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-hobbi-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-keszsegek-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oneletrajz-keszsegek.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-keszsegek-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-tanulmanyok-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oneletrajz-tanulmanyok.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-tanulmanyok-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-tipusok-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oneletrajz-tipusok.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oneletrajz-tipusok-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-oszinte-szavak-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/oszinte-szavak.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-oszinte-szavak-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-palyakezdo-oneletrajz-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/palyakezdo-oneletrajz.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-palyakezdo-oneletrajz-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-szemelyes-bemutatkozas-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/szemelyes-bemutatkozas.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-szemelyes-bemutatkozas-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-hu-top-10-hiba-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=C:/andras/synced/cvchamp-landing-next/cvchamp-landing-next/src/blog/hu/top-10-hiba.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-hu-top-10-hiba-mdx" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

